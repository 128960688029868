import React, { memo, useEffect, useState, useRef } from 'react'
import { Videos, VideosItem } from '@types'
import { MediaScrollButtons } from '@components'
import { useWindowSize, useScrollDirection } from '@hooks'
import styles from './media-watch.module.scss'
import { doc } from 'prettier'
import { useInView } from 'react-intersection-observer'

function YouTubeGetID(url) {
  let ID = ''
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i)
    ID = ID[0]
  } else {
    ID = url
  }
  return ID
}

const replaceSpace = (url: string) => {
  return url.split(' ').join('%20') /// add symbol space
}

interface NewsWatchProps {
  videos: Videos
}

const NewsWatch: React.FC<NewsWatchProps> = props => {
  const { videos } = props
  const { width } = useWindowSize()

  const scroller = useRef<HTMLDivElement | null>(null)
  const [isModalOpened, setModalOpened] = useState(false)
  const [youtubeId, setYoutubeId] = useState('')
  const [leftRef, leftRefInView] = useInView({ triggerOnce: false, rootMargin: '0px 0px', threshold: 0.2 })
  const [rightRef, rightRefInView] = useInView({ triggerOnce: false, rootMargin: '0px 0px', threshold: 0.2 })

  const handleVideoClick = link => {
    if (link.includes('youtube')) {
      setModalOpened(true)
      setYoutubeId(YouTubeGetID(link))
    }
  }

  return (
    <div className={styles.mediaWatch}>
      {isModalOpened && (
        <div className={styles.modal}>
          <div className={styles.modalBackground} onClick={() => setModalOpened(false)} />
          <div className={styles.modalClose} onClick={() => setModalOpened(false)}>
            <div />
            <div />
            <div />
          </div>
          <iframe
            className={styles.modalVideo}
            width="644"
            height="362"
            src={`https://www.youtube.com/embed/${youtubeId}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      )}
      <div className={styles.mediaHeader}>
        <h2 className={styles.mediaHeaderTitle}>
          Featured <span>Watch</span>
        </h2>
        <MediaScrollButtons leftInView={leftRefInView} rightInView={rightRefInView} scroller={scroller} />
      </div>
      <div ref={scroller} className={styles.mediaVideoContainer + ' news-slider-scroll'} data-news-slider-scroll={true}>
        {videos?.map((video: VideosItem, index: Number) => {
          const { id, title, link, platform, avatar, description } = video

          if (!link?.includes('youtube')) {
            return (
              <a key={id} className={styles.mediaVideoCard} href={link} target="_blank">
                {index === 0 && <div ref={leftRef} />}
                {index === videos.length - 1 && <div ref={rightRef} />}
                <div className={styles.mediaVideoCardImage}>
                  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d)">
                      <path
                        d="M32 56C45.2548 56 56 45.2548 56 32C56 18.7452 45.2548 8 32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56Z"
                        stroke="#FAF9F9"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="bevel"
                      />
                      <path d="M39 32L29 38V26L39 32Z" fill="#FAF9F9" />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d"
                        x="0.5"
                        y="0.5"
                        width="63"
                        height="63"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                      </filter>
                    </defs>
                  </svg>
                  <div className={styles.mediaVideoCardImageOverlay}></div>
                  <div
                    className={styles.mediaVideoCardImageSource}
                    style={{
                      backgroundImage: avatar ? `url(https://palosanto.vc${replaceSpace(avatar)})` : ''
                    }}
                  />
                </div>

                <div className={styles.mediaVideoCardSource}>{platform}</div>
                <div className={styles.mediaVideoCardTitle}>{title}</div>
                <div className={styles.mediaVideoCardDescription}>{description}</div>
              </a>
            )
          }

          return (
            <div key={id} className={styles.mediaVideoCard} onClick={() => handleVideoClick(link)}>
              {index === 0 && <div ref={leftRef} />}
              {index === videos.length - 1 && <div ref={rightRef} />}
              <div className={styles.mediaVideoCardImage}>
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d)">
                    <path
                      d="M32 56C45.2548 56 56 45.2548 56 32C56 18.7452 45.2548 8 32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56Z"
                      stroke="#FAF9F9"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="bevel"
                    />
                    <path d="M39 32L29 38V26L39 32Z" fill="#FAF9F9" />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="0.5"
                      y="0.5"
                      width="63"
                      height="63"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="3" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                  </defs>
                </svg>
                <div className={styles.mediaVideoCardImageOverlay}></div>
                <div
                  className={styles.mediaVideoCardImageSource}
                  style={{
                    backgroundImage: `url(https://img.youtube.com/vi/${YouTubeGetID(link)}/0.jpg)`
                  }}
                />
              </div>

              <div className={styles.mediaVideoCardSource}>{platform}</div>
              <div className={styles.mediaVideoCardTitle}>{title}</div>
              <div className={styles.mediaVideoCardDescription}>{description}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default memo(NewsWatch)
