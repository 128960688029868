import { useEffect, useState } from 'react'

const useElementScroll = <T = HTMLDivElement>(element: T, callback?: (scroll: number[]) => void): number[] => {
  if (typeof document === 'undefined') return [0, 0]

  const handleElementScroll = () => {
    const elementXScroll = element?.scrollLeft || 0
    const elementYScroll = element?.scrollTop || 0

    callback && callback([elementXScroll, elementYScroll])
    return [elementXScroll, elementYScroll]
  }

  const [isListeners, setIsListeners] = useState(false)
  const [elementScroll, setElementScroll] = useState<number[]>(handleElementScroll)

  useEffect(() => {
    if (element && !isListeners) {
      setIsListeners(true)

      const handleScroll = () => {
        setElementScroll(handleElementScroll())
      }

      element.addEventListener('scroll', handleScroll)
      return () => element.removeEventListener('scroll', handleScroll)
    }
  }, [element])

  return elementScroll
}

export default useElementScroll
